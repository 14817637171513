import clsx from 'clsx'
import { Box, Skeleton } from '@mui/material'
import styles from './CarouselSkeleton.module.scss'

type CarouselSkeletonProps = {
  elementsPerRow: number
  withNavigation?: boolean
  classes?: {
    root?: string
    content?: string
  }
}

export const CarouselSkeleton = ({
  elementsPerRow,
  classes,
  withNavigation = true,
}: CarouselSkeletonProps) => {
  if (!elementsPerRow) return null

  return (
    <Box className={styles.carouselSkeletonWrapper}>
      <Box className={clsx(styles.carouselSkeletonMain, classes?.content)}>
        {withNavigation && (
          <Skeleton
            className={styles.carouselSkeletonArrow}
            variant="rectangular"
            animation="wave"
          />
        )}
        {new Array(elementsPerRow).fill(null).map((_, idx) => {
          return (
            <Skeleton
              className={styles.carouselSkeletonCard}
              key={idx}
              variant="rectangular"
              animation="wave"
            ></Skeleton>
          )
        })}
        {withNavigation && (
          <Skeleton
            className={styles.carouselSkeletonArrow}
            variant="rectangular"
            animation="wave"
          />
        )}
      </Box>
      {withNavigation && (
        <Skeleton
          className={styles.carouselSkeletonNavigation}
          variant="rectangular"
          animation="wave"
        />
      )}
    </Box>
  )
}
