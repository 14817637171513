import {
  PersonalRecommendationsGroup,
  RecommendationProduct,
} from '@obeta/models/lib/models/Product/RecommendationProduct'
import { datadogRum } from '@datadog/browser-rum'

export const formatProductData = (product: RecommendationProduct) => ({
  sap_id: product.sapId,
  grouping: product.grouping,
  stock_online: product.stock?.reduce((sum, stock) => sum + stock.amount, 0),
  price: product.prices?.netPrice,
})

export const sendClickEvent = (product: RecommendationProduct) => {
  datadogRum.addAction('PERSONAL_REC_CLICK', {
    PRODUCT: formatProductData(product),
  })
}

const SWIPE_EVENTS = {
  left: 'PERSONAL_REC_LEFT_SWIPE_CLICK',
  right: 'PERSONAL_REC_RIGHT_SWIPE_CLICK',
}

export const sendSwipeClickEvent = (
  swipeDirection: keyof typeof SWIPE_EVENTS,
  group?: PersonalRecommendationsGroup
) => {
  datadogRum.addAction(SWIPE_EVENTS[swipeDirection], {
    GROUP: group,
  })
}

export const sendAddArticleToCardEvent = (
  selectedCartId: string,
  product: RecommendationProduct
) => {
  datadogRum.addAction('PERSONAL_REC_ADD_TO_CART', {
    CARTID: selectedCartId,
    PRODUCT: formatProductData(product),
  })
}

export const sendRenderEvent = (cachedRecommendations: RecommendationProduct[]) => {
  datadogRum.addAction('PERSONAL_REC_RENDER', {
    PRODUCTS: cachedRecommendations.map(formatProductData),
  })
}
